import { ColorPalette } from '../../../types-generated'
import { DEFAULT_CHART_OPTIONS, DOT_RADIUS } from '../consts'
import { getColor, openInNewTab } from '../utils'
import { Radar, RadarProps } from 'recharts'

type RadarPoint = NonNullable<RadarProps['points']>[0]

export const renderRadar = (keys: string[], colorPalette: ColorPalette) =>
  keys.map((key: string, index: number) => (
    <Radar
      key={key}
      dataKey={key}
      stroke={getColor(index, colorPalette)}
      fill={getColor(index, colorPalette)}
      fillOpacity={0.6}
      isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
      activeDot={{
        r: DOT_RADIUS,
        onClick: (_event, payload: unknown) =>
          (payload as RadarPoint)?.payload?.drillDownUrl &&
          openInNewTab((payload as RadarPoint).payload.drillDownUrl),
      }}
    />
  ))
