import { SnackOptions } from '../../types'
import { MetricWidget } from '../../types-generated'
import styles from '../../widget.module.styl'
import { About } from '../About'
import { WidgetMenuItemConfig, WidgetMenuResolver } from '../Menu/WidgetMenuResolver'
import { Text } from '@nike/eds'
import { useState } from 'react'

interface WidgetHeaderProps {
  widget: MetricWidget
  menuConfig?: WidgetMenuItemConfig[]
  createSnack?: (options: SnackOptions) => void
}

export const WidgetHeader = ({ widget, menuConfig, createSnack }: WidgetHeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  return (
    <div className={styles.header}>
      <Text
        font='subtitle-1'
        data-testid='widget-name'
        className={styles.elipsis}
        title={widget.name}
      >
        {widget.name}
      </Text>
      <div className={styles.widgetActions}>
        <About widget={widget} />
        {!!menuConfig?.length && (
          <WidgetMenuResolver
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            config={menuConfig}
            id={widget.id || widget.name}
            createSnack={createSnack}
          />
        )}
      </div>
    </div>
  )
}
