// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-module__chart--YyF0F{font-size:10px}.chart-module__chart--YyF0F.chart-module__small--goMB5{font-size:10px}.chart-module__chart--YyF0F.chart-module__large--rxAAV{font-size:14px}.chart-module__chart--YyF0F .recharts-tooltip-label{font-weight:600}.chart-module__customTooltip--fcTub{padding:var(--eds-space-8) var(--eds-space-12);background-color:var(--eds-color-background-default);border:1px solid var(--eds-color-border-primary)}.chart-module__customTooltip--fcTub .chart-module__item--jXDtC{display:flex;align-items:center}.chart-module__customTooltip--fcTub .chart-module__itemText--rn7NK{display:flex;justify-content:space-between;flex:1;gap:var(--eds-space-4)}.chart-module__customTooltip--fcTub .chart-module__square--l0WIm{height:10px;width:10px;border-radius:1px;margin-right:var(--eds-space-8)}.chart-module__customTooltip--fcTub .chart-module__label--ZESeM{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/Charts/AxesChart/chart.module.styl"],"names":[],"mappings":"AAAA,4BACE,cAAU,CACV,uDACE,cAAU,CACZ,uDACE,cAAU,CAEZ,oDACE,eAAY,CAEhB,oCACE,8CAAQ,CACR,oDAAiB,CACjB,gDAAO,CAEP,+DACE,YAAQ,CACR,kBAAY,CAEd,mEACE,YAAQ,CACR,6BAAgB,CAChB,MAAK,CACL,sBAAK,CAEP,iEACE,WAAO,CACP,UAAM,CACN,iBAAc,CACd,+BAAa,CAGf,gEACE,eAAY","sourcesContent":[".chart\n  font-size 10px\n  &.small\n    font-size 10px\n  &.large\n    font-size 14px\n\n  :global(.recharts-tooltip-label)\n    font-weight 600\n\n.customTooltip\n  padding var(--eds-space-8) var(--eds-space-12)\n  background-color var(--eds-color-background-default)\n  border 1px solid var(--eds-color-border-primary)\n\n  .item\n    display flex\n    align-items center\n\n  .itemText \n    display flex\n    justify-content space-between\n    flex 1   \n    gap  var(--eds-space-4)\n\n  .square\n    height 10px\n    width 10px\n    border-radius 1px\n    margin-right var(--eds-space-8)\n\n\n  .label \n    font-weight 600"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `chart-module__chart--YyF0F`,
	"small": `chart-module__small--goMB5`,
	"large": `chart-module__large--rxAAV`,
	"customTooltip": `chart-module__customTooltip--fcTub`,
	"item": `chart-module__item--jXDtC`,
	"itemText": `chart-module__itemText--rn7NK`,
	"square": `chart-module__square--l0WIm`,
	"label": `chart-module__label--ZESeM`
};
export default ___CSS_LOADER_EXPORT___;
