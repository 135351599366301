import { labels } from '../../constants'
import { WidgetSize } from '../../types-generated'
import styles from '../../widget.module.styl'
import { Collapsible } from '../Collapsible'
import { SizeIcon } from './SizeIcon'
import { Spinner, Text } from '@nike/eds'
import classnames from 'classnames'

interface ResizeWidgetProps {
  currentSize?: WidgetSize
  isUpdatingSize?: boolean
  handleResizeWidget?: (size: WidgetSize) => void
  toggleMenu?: () => void
  id?: string
  classNames?: string
}

const WIDGET_SIZES = Object.values(WidgetSize)

export const ResizeWidget = ({
  currentSize,
  handleResizeWidget,
  isUpdatingSize,
  toggleMenu,
  id,
  classNames,
}: ResizeWidgetProps) => (
  <li
    className={classnames(classNames, 'eds-menu-item', {
      [styles.disabled]: isUpdatingSize,
    })}
  >
    <Collapsible
      title={
        <Text<'span'> as='span' font='body-3'>
          {labels.menu.resize}
        </Text>
      }
      innerClassName={styles.sizesContainer}
      transitionTime={30}
      className={styles.resize}
      id={id}
    >
      <div className={styles.sizeButtons}>
        {isUpdatingSize ? (
          <Spinner data-testid='resize-spinner' />
        ) : (
          WIDGET_SIZES.map(
            (size) =>
              size !== currentSize && (
                <button
                  key={size}
                  onClick={() => {
                    handleResizeWidget?.(size)
                    toggleMenu && toggleMenu()
                  }}
                  name={size}
                  data-testid={`size-button-${size}`}
                >
                  <SizeIcon size={size} />
                </button>
              )
          )
        )}
      </div>
    </Collapsible>
  </li>
)
