import { WidgetSize } from '../../../types-generated'
import { DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES } from '../consts'
import type { CellDetailed, DataItem, EntryDetails, TableColumn } from '../types'
import { getChartKeys } from '../utils'
import styles from './tableChart.module.styl'
import { IconButton, Link, Table, TableCell, TableHeading, Text, Tooltip } from '@nike/eds'
import { SortAscending, SortDescending } from '@nike/nike-design-system-icons'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Table as TableType,
  CellContext,
} from '@tanstack/react-table'
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface TableChartProps {
  data?: DataItem[]
  columns?: TableColumn[]
  isExpanded: boolean
  widgetSize?: WidgetSize
}

const getColumns = (columnsData: TableColumn[], data: DataItem[]) => {
  if (columnsData.length) {
    return columnsData.map((column) => ({
      header: column.name,
      accessorKey: column.name,
      enableSorting: !column.isSortingDisabled,
    }))
  }
  const keys = getChartKeys(data, ['drillDownUrl'])
  return keys.map((column) => ({
    header: column,
    accessorKey: column,
  }))
}

const ROWS_PER_SMALL_CARD = 4
const ROWS_PER_LARGE_CARD = 12

const getPageSize = (isExpanded: boolean, widgetSize?: WidgetSize) => {
  if (
    isExpanded ||
    (widgetSize && (widgetSize === WidgetSize.BIGSQUARE || widgetSize === WidgetSize.VERTICALRECT))
  ) {
    return ROWS_PER_LARGE_CARD
  } else {
    return ROWS_PER_SMALL_CARD
  }
}

const isCellDetailed = (cell: any): cell is CellDetailed =>
  cell &&
  cell.name &&
  ((cell.tooltip && (typeof cell.tooltip === 'string' || Array.isArray(cell.tooltip))) ||
    cell.drillDownUrl)

const getTooltipContent = (
  tooltip: string | EntryDetails[],
  header: string,
  isExpanded: boolean
) => {
  if (typeof tooltip === 'string') {
    return tooltip
  }

  const items = isExpanded ? tooltip : tooltip.slice(0, DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES)
  return (
    <>
      <h2>{header}</h2>
      <ul>
        {items.map(({ label, value }, index) => (
          <li title={value} key={`${label}-${index}`}>
            <span>{label}:</span>
            {value}
          </li>
        ))}
      </ul>
      {!isExpanded && tooltip.length > DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES && (
        <Text font='legal-1' className='eds-spacing--mt-4 eds-spacing--pr-8'>
          Expand Widget to see more
        </Text>
      )}
    </>
  )
}

const renderCell = (
  cell: CellContext<DataItem, number | string | CellDetailed>,
  isExpanded: boolean
) => {
  const cellValue = cell.getValue()

  const Cell = () => (
    <Text<'span'> as='span' font='body-3' className={styles.cellContent}>
      {isCellDetailed(cellValue) && cellValue.drillDownUrl ? (
        <Link href={cellValue.drillDownUrl} target='_blank'>
          {cellValue.name}
        </Link>
      ) : (
        isCellDetailed(cellValue) && cellValue.name
      )}
    </Text>
  )

  if (typeof cellValue === 'string' || typeof cellValue === 'number') {
    return (
      <Text<'span'> as='span' font='body-3' className={styles.cellContent}>
        {cellValue}
      </Text>
    )
  } else if (isCellDetailed(cellValue)) {
    return (
      <>
        {cellValue.tooltip ? (
          <Tooltip
            bodySlot={getTooltipContent(cellValue.tooltip, cell.column.id, isExpanded)}
            className={styles.tooltipContent}
          >
            <Cell />
          </Tooltip>
        ) : (
          <Cell />
        )}
      </>
    )
  }
}

const getPaginationCounts = (table: TableType<DataItem>) => {
  const paginationState = table.getState().pagination
  const pageFirstIndex = paginationState.pageIndex * paginationState.pageSize + 1
  const pageLastIndex = pageFirstIndex + paginationState.pageSize - 1
  const totalItems = table.getRowCount()

  return `${pageFirstIndex} - ${
    pageLastIndex > totalItems ? totalItems : pageLastIndex
  }  of  ${totalItems}`
}

export const TableChart = ({
  data = [],
  columns = [],
  widgetSize,
  isExpanded,
}: TableChartProps) => {
  const columnsHeaders = useMemo(() => getColumns(columns, data), [columns, data])

  const getInitialState = useCallback(
    () => ({
      pageIndex: 0,
      pageSize: getPageSize(isExpanded, widgetSize),
    }),
    [isExpanded, widgetSize]
  )

  const [pagination, setPagination] = useState(getInitialState)

  useEffect(() => {
    setPagination(getInitialState)
  }, [getInitialState, isExpanded, widgetSize])

  const table = useReactTable({
    columns: columnsHeaders,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })

  if (!data || Object.keys(data).length === 0) {
    return null
  }

  return (
    <div className={styles.tableChartWrapper}>
      <div className={styles.tableScrollWrapper}>
        <Table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeading
                    key={header.id}
                    className={classnames(
                      styles.cellContent,
                      'eds-type--subtitle-2',
                      'eds-color--default',
                      {
                        [styles.expanded]: isExpanded,
                      }
                    )}
                    title={header.column.id}
                    colSpan={header.colSpan}
                  >
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className={classnames(styles.cellWrapper, {
                        [styles.isSortable]: header.column.getCanSort(),
                      })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <SortAscending size='s' className={styles.sortIcon} />,
                        desc: <SortDescending size='s' className={styles.sortIcon} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </TableHeading>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={classnames({ [styles.expanded]: isExpanded })}
                  >
                    {renderCell(cell.getContext(), isExpanded)}
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className={styles.pagination}>
        <IconButton
          size='small'
          variant='ghost'
          icon='CaretLeft'
          label='Previous page'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        />
        <span>{getPaginationCounts(table)}</span>
        <IconButton
          size='small'
          variant='ghost'
          icon='CaretRight'
          label='Next page'
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        />
      </div>
    </div>
  )
}
