import { ColorPalette } from '../../../types-generated'
import { DEFAULT_CHART_OPTIONS } from '../consts'
import type { DataItem } from '../types'
import { getColor, is2DArray, openInNewTab } from '../utils'
import { Cell, Scatter } from 'recharts'

export const renderScatter = (
  colorPalette: ColorPalette,
  categories: string[],
  // eslint-disable-next-line
  data?: any[] // need to be any here
) => {
  const renderSingleScatter = (data?: DataItem[], index = 0) => (
    <Scatter
      key={index}
      data={data}
      fill={getColor(index, colorPalette)}
      isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
      name={categories[index]}
    >
      {data?.map((entry, index) => (
        <Cell
          key={`cell-${index}`}
          onClick={() => typeof entry.drillDownUrl === 'string' && openInNewTab(entry.drillDownUrl)}
        />
      ))}
    </Scatter>
  )

  if (is2DArray(data ?? [])) {
    return <>{data?.map((singleData, index) => renderSingleScatter(singleData, index))}</>
  } else return renderSingleScatter(data)
}
