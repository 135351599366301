import { SnackOptions } from '../../types'
import { UserMetricWidget } from '../../types-generated'
import { getWidgetUrl } from '../../utils/getWidgetUrl'
import styles from '../../widget.module.styl'
import { ChartData, ChartOptions } from '../Charts'
import { WidgetContent } from '../WidgetContent/WidgetContent'
import { Button, ButtonGroup, Modal, Text } from '@nike/eds'
import { Link } from '@nike/nike-design-system-icons'

export interface WidgetModalProps<T> {
  widget: T & { isFilteredOut?: boolean }
  widgetData?: ChartData
  isShareable?: boolean
  isFetching?: boolean
  errorMessage?: string
  closeModal: () => void
  createSnack?: (options: SnackOptions) => void
  additionalButtons?: JSX.Element
  chartOptions?: ChartOptions
  getRefreshedWidgetData?: () => Promise<void>
}

export const WidgetModal = <T extends UserMetricWidget>({
  closeModal,
  widget,
  widgetData,
  createSnack,
  isShareable,
  isFetching = false,
  errorMessage,
  additionalButtons,
  chartOptions,
  getRefreshedWidgetData,
}: WidgetModalProps<T>) => {
  const handleClickOnCopyURL = async () => {
    widget && (await navigator.clipboard.writeText(getWidgetUrl(widget.id || widget.name)))
    createSnack?.({
      id: 'widget-link-copied',
      status: 'success',
      message: 'Link copied to clipboard',
    })
  }

  return (
    <Modal
      isOpen
      displayCheckOverride={process.env.NODE_ENV === 'test'}
      onDismiss={closeModal}
      className={styles.widgetDetailsModal}
      headerSlot={widget.name}
      data-testid='expanded-widget'
      footerSlot={
        <ButtonGroup>
          <Button size='small' onClick={closeModal}>
            Close
          </Button>
          {additionalButtons}
          {isShareable && !!createSnack && !widget.customWidgetId && (
            <Button
              size='small'
              variant='secondary'
              beforeSlot={<Link />}
              onClick={handleClickOnCopyURL}
            >
              Copy Link
            </Button>
          )}
        </ButtonGroup>
      }
      hideFade
    >
      <Text font='body-3' className='eds-spacing--mb-32'>
        {widget.description}
      </Text>
      <WidgetContent
        widget={widget}
        chartOptions={chartOptions}
        widgetData={widgetData}
        isFetching={isFetching}
        errorMessage={errorMessage}
        getRefreshedWidgetData={getRefreshedWidgetData}
        isExpanded
      />
    </Modal>
  )
}
