export type AxesConfig = {
  axisYLimit?: Maybe<AxisLimit>
  xAxisLabel?: Maybe<Scalars['String']['output']>
  xAxisUnit?: Maybe<Scalars['String']['output']>
  yAxisLabel?: Maybe<Scalars['String']['output']>
  yAxisUnit?: Maybe<Scalars['String']['output']>
}
export type AxisLimit = {
  max?: Maybe<Scalars['Int']['output']>
  min?: Maybe<Scalars['Int']['output']>
}
export type ChartAppearance = {
  axesConfig?: Maybe<AxesConfig>
  chartType?: Maybe<ChartType>
  colorPalette?: Maybe<ColorPalette>
  showChartLegend?: Maybe<Scalars['Boolean']['output']>
  size?: Maybe<WidgetSize>
  withBrush?: Maybe<Scalars['Boolean']['output']>
}
export enum ChartType {
  AREACHART = 'AreaChart',
  BARCHART = 'BarChart',
  BIGNUMBERCHART = 'BigNumberChart',
  CALENDAR = 'Calendar',
  FORM = 'Form',
  LINECHART = 'LineChart',
  LISTCHART = 'ListChart',
  PIECHART = 'PieChart',
  RADARCHART = 'RadarChart',
  SCATTERCHART = 'ScatterChart',
  STACKEDAREACHART = 'StackedAreaChart',
  STACKEDBARCHART = 'StackedBarChart',
  TABLE = 'Table',
}
export enum ColorPalette {
  CATEGORICAL = 'categorical',
  SEQUENTIAL = 'sequential',
}
export type DataSource = {
  cxWidgetPathname?: Maybe<Scalars['String']['output']>
  gqlQuery?: Maybe<Scalars['String']['output']>
  queryParamsSupport?: Maybe<Scalars['Boolean']['output']>
  refreshFrequency: Scalars['Long']['output']
  supportedParams?: Maybe<Array<SupportedParam>>
  timezoneSupport?: Maybe<Scalars['Boolean']['output']>
  type: DataSourceType
  url: Scalars['AWSURL']['output']
}
export enum DataSourceType {
  API = 'api',
  CX = 'cx',
  GITHUB = 'github',
  GRAPHQL = 'graphql',
  SIGNALFX = 'signalFx',
  SPLUNK = 'splunk',
}
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type MetricWidget = {
  attributes?: Maybe<WidgetAttributes>
  chartAppearance?: Maybe<ChartAppearance>
  contacts: WidgetContacts
  createdOn?: Maybe<Scalars['Long']['output']>
  dataSource: DataSource
  description: Scalars['String']['output']
  examples?: Maybe<Array<Maybe<WidgetExample>>>
  id?: Maybe<Scalars['String']['output']>
  lifecycle: WidgetLifecycle
  name: Scalars['String']['output']
  relatedTechSolutions?: Maybe<Array<Maybe<WidgetRelatedTechSolution>>>
  scope: WidgetScope
  tags: Array<Maybe<Scalars['String']['output']>>
  updatedBy?: Maybe<Scalars['String']['output']>
  updatedOn?: Maybe<Scalars['Long']['output']>
}
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AWSDate: { input: string; output: string }
  AWSDateTime: { input: string; output: string }
  AWSJSON: { input: string; output: string }
  AWSURL: { input: string; output: string }
  Long: { input: number; output: number }
}
export type SupportedParam = {
  defaultValue?: Maybe<Scalars['String']['output']>
  isRequired?: Maybe<Scalars['Boolean']['output']>
  parameter: Scalars['String']['output']
  sampleValue?: Maybe<Scalars['String']['output']>
}
export type UserMetricWidget = {
  attributes?: Maybe<WidgetAttributes>
  chartAppearance?: Maybe<ChartAppearance>
  contacts: WidgetContacts
  createdOn?: Maybe<Scalars['Long']['output']>
  customWidgetId?: Maybe<Scalars['ID']['output']>
  dataSource: DataSource
  description: Scalars['String']['output']
  examples?: Maybe<Array<Maybe<WidgetExample>>>
  id?: Maybe<Scalars['String']['output']>
  lifecycle: WidgetLifecycle
  name: Scalars['String']['output']
  queryParams?: Maybe<Array<UserWidgetQueryParams>>
  relatedTechSolutions?: Maybe<Array<Maybe<WidgetRelatedTechSolution>>>
  scope: WidgetScope
  tags: Array<Maybe<Scalars['String']['output']>>
  updatedBy?: Maybe<Scalars['String']['output']>
  updatedOn?: Maybe<Scalars['Long']['output']>
}
export type UserWidgetQueryParams = {
  parameter: Scalars['String']['output']
  value: Scalars['String']['output']
}
export type WidgetAttributes = {
  favorite?: Maybe<Scalars['Boolean']['output']>
  new?: Maybe<Scalars['Boolean']['output']>
  recommended?: Maybe<Scalars['Boolean']['output']>
}
export type WidgetContacts = {
  maintainerEmail: Scalars['String']['output']
  maintainerSlack: Scalars['String']['output']
  moreInfoURL?: Maybe<Scalars['AWSURL']['output']>
}
export type WidgetExample = {
  data: Scalars['AWSJSON']['output']
  description: Scalars['String']['output']
  name: Scalars['String']['output']
}
export type WidgetFilters = {
  default?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  enum?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  maxDate?: Maybe<Scalars['AWSDate']['output']>
  minDate?: Maybe<Scalars['AWSDate']['output']>
  name: Scalars['String']['output']
  pattern?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}
export type WidgetFiltersInput = {
  default?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  enum?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  maxDate?: InputMaybe<Scalars['AWSDate']['input']>
  minDate?: InputMaybe<Scalars['AWSDate']['input']>
  name: Scalars['String']['input']
  pattern?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
}
export type WidgetLifecycle = {
  message?: Maybe<Scalars['String']['output']>
  state: WidgetLifecycleState
}
export type WidgetLifecycleInput = {
  message?: InputMaybe<Scalars['String']['input']>
  state: WidgetLifecycleState
}
export enum WidgetLifecycleState {
  DEPRECATED = 'deprecated',
  PENDING = 'pending',
  PRERELEASE = 'prerelease',
  RELEASE = 'release',
  RETIRED = 'retired',
}
export type WidgetRelatedTechSolution = {
  shouldPublishWidget?: Maybe<Scalars['Boolean']['output']>
  techSolutionId?: Maybe<Scalars['ID']['output']>
}
export enum WidgetScope {
  SYSTEMIC = 'systemic',
  USER = 'user',
}
export enum WidgetSize {
  BIGSQUARE = 'bigSquare',
  HORIZONTALRECT = 'horizontalRect',
  SMALLSQUARE = 'smallSquare',
  VERTICALRECT = 'verticalRect',
}
