// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sizeIcon-module__wrapper--f70U9{display:grid;grid-template-columns:9px 9px;grid-template-rows:9px 9px;grid-gap:2px;padding:5px}.sizeIcon-module__wrapper--f70U9 div{border:1px solid var(--eds-color-text-default)}.sizeIcon-module__verticalRect--ITSIk *:nth-child(1),.sizeIcon-module__verticalRect--ITSIk *:nth-child(3){background-color:var(--eds-color-text-default)}.sizeIcon-module__horizontalRect--XbGmB *:nth-child(1),.sizeIcon-module__horizontalRect--XbGmB *:nth-child(2){background-color:var(--eds-color-text-default)}.sizeIcon-module__smallSquare--ej8pf *:nth-child(1){background-color:var(--eds-color-text-default)}.sizeIcon-module__bigSquare--ZglUT *{background-color:var(--eds-color-text-default)}`, "",{"version":3,"sources":["webpack://./src/components/Menu/sizeIcon.module.styl"],"names":[],"mappings":"AAAA,iCACE,YAAQ,CACR,6BAAsB,CACtB,0BAAmB,CACnB,YAAS,CACT,WAAQ,CAER,qCACE,8CAAO,CAGT,0GAEE,8CAAiB,CAGnB,8GAEE,8CAAiB,CAGnB,oDACE,8CAAiB,CAGnB,qCACE,8CAAiB","sourcesContent":[".wrapper\n  display grid\n  grid-template-columns 9px 9px\n  grid-template-rows 9px 9px\n  grid-gap 2px\n  padding 5px\n\n  div\n    border 1px solid var(--eds-color-text-default)\n\n.verticalRect\n  *:nth-child(1),\n  *:nth-child(3)\n    background-color var(--eds-color-text-default)\n\n.horizontalRect\n  *:nth-child(1),\n  *:nth-child(2)\n    background-color var(--eds-color-text-default)\n\n.smallSquare\n  *:nth-child(1)\n    background-color var(--eds-color-text-default)\n\n.bigSquare\n  *\n    background-color var(--eds-color-text-default)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `sizeIcon-module__wrapper--f70U9`,
	"verticalRect": `sizeIcon-module__verticalRect--ITSIk`,
	"horizontalRect": `sizeIcon-module__horizontalRect--XbGmB`,
	"smallSquare": `sizeIcon-module__smallSquare--ej8pf`,
	"bigSquare": `sizeIcon-module__bigSquare--ZglUT`
};
export default ___CSS_LOADER_EXPORT___;
