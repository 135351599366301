import { ChartSize } from '../types'
import { ListChartItem } from './ListChart'
import styles from './listChart.module.styl'
import { Link, Text } from '@nike/eds'
import { CaretDown, CaretUp } from '@nike/nike-design-system-icons'
import classnames from 'classnames'
import { useState } from 'react'

interface ItemWithDetailsProps {
  item: ListChartItem
  size?: ChartSize
}

export const ItemWithDetails = ({ item, size }: ItemWithDetailsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { details, name } = item

  return (
    <Text<'li'>
      as='li'
      onClick={() => setIsOpen(!isOpen)}
      className={classnames(styles.listItem, styles.listItemWithDetails)}
      font={size === 'small' ? 'subtitle-2' : 'subtitle-1'}
    >
      {name}
      {isOpen && (
        <ul className={styles.details}>
          {Array.isArray(details) &&
            details.map((entry, index) => (
              <Text<'li'> as='li' font='body-3' title={entry.value} key={`${entry.label}-${index}`}>
                <span>{entry.label}: </span>
                {entry?.drillDownUrl ? (
                  <Link target='_blank' href={entry.drillDownUrl}>
                    {entry.value}
                  </Link>
                ) : (
                  entry.value
                )}
              </Text>
            ))}
        </ul>
      )}
      {isOpen ? (
        <CaretUp className={styles.caretIcon} />
      ) : (
        <CaretDown className={styles.caretIcon} />
      )}
    </Text>
  )
}
