import { ChartData } from './components/Charts'
import { MenuType } from './components/Menu/WidgetMenu'
import { WidgetMenuItemConfig } from './components/Menu/WidgetMenuResolver'
import { Tags } from './components/Tags'
import { WidgetContent } from './components/WidgetContent/WidgetContent'
import { WidgetHeader } from './components/WidgetHeader/WidgetHeader'
import { WidgetModal } from './components/WidgetModal/WidgetModal'
import { WidgetSidePanel, hasSaveWidgetHandler } from './components/WidgetSidePanel'
import { SnackOptions, WidgetStyles } from './types'
import { UserMetricWidget, WidgetLifecycleState, WidgetSize } from './types-generated'
import { AccessTokenContext } from './utils/accessTokenProvider'
import styles from './widget.module.styl'
import { Card, Tag } from '@nike/eds'
import classnames from 'classnames'
import { useState } from 'react'
import { createPortal } from 'react-dom'

const getCardAdditionalStyles = (
  widgetSize: WidgetSize,
  isFilteredOut?: boolean,
  widgetStyles?: WidgetStyles
) => {
  const verticalRect = widgetStyles?.verticalRect || styles.verticalRect
  const horizontalRect = widgetStyles?.horizontalRect || styles.horizontalRect
  const smallSquare = widgetStyles?.smallSquare || styles.smallSquare
  const bigSquare = widgetStyles?.bigSquare || styles.bigSquare

  return {
    [verticalRect]: widgetSize === WidgetSize.VERTICALRECT,
    [horizontalRect]: widgetSize === WidgetSize.HORIZONTALRECT,
    [smallSquare]: widgetSize === WidgetSize.SMALLSQUARE,
    [bigSquare]: widgetSize === WidgetSize.BIGSQUARE,
    [styles.isHidden]: isFilteredOut,
  }
}

interface WidgetCardProps<T> {
  widget: T & { isFilteredOut?: boolean }
  id?: string
  widgetMenuConfig?: WidgetMenuItemConfig[]
  isFetching: boolean
  errorMessage: string
  widgetData?: ChartData
  widgetStyles?: WidgetStyles
  isShareable?: boolean
  isResizable?: boolean
  getRefreshedWidgetData?: () => Promise<void>
  createSnack?: (options: SnackOptions) => void
  accessToken?: string
}

export const WidgetCard = <T extends UserMetricWidget>({
  widget,
  id,
  widgetMenuConfig = [],
  isFetching,
  errorMessage,
  widgetData,
  widgetStyles,
  isShareable = false,
  isResizable = false,
  getRefreshedWidgetData,
  createSnack,
  accessToken = '',
}: WidgetCardProps<T>) => {
  const [isWidgetDetailsOpen, setIsWidgetDetailsOpen] = useState(false)
  const handleWidgetDetailsModal = () => setIsWidgetDetailsOpen(!isWidgetDetailsOpen)
  const [isEditQueryOpen, setIsEditQueryOpen] = useState(false)
  const { chartAppearance } = widget
  const widgetSize = isResizable
    ? chartAppearance?.size || WidgetSize.SMALLSQUARE
    : WidgetSize.SMALLSQUARE

  const chartOptions = {
    xAxisLabel: chartAppearance?.axesConfig?.xAxisLabel || widgetData?.xAxisLabel,
    yAxisLabel: chartAppearance?.axesConfig?.yAxisLabel || widgetData?.yAxisLabel,
    xAxisUnit: chartAppearance?.axesConfig?.xAxisUnit || widgetData?.xAxisUnit,
    yAxisUnit: chartAppearance?.axesConfig?.yAxisUnit || widgetData?.yAxisUnit,
    legend:
      chartAppearance?.showChartLegend !== null
        ? chartAppearance?.showChartLegend
        : widgetData?.legend,
    withBrush:
      chartAppearance?.withBrush !== null ? chartAppearance?.withBrush : widgetData?.withBrush,
    axisYLimit:
      chartAppearance?.axesConfig?.axisYLimit?.min !== undefined &&
      chartAppearance?.axesConfig?.axisYLimit?.max
        ? chartAppearance?.axesConfig?.axisYLimit
        : widgetData?.axisYLimit,
    categories: widgetData?.categories,
  }

  const updatedWidgetMenuConfig = [...widgetMenuConfig]

  // expand
  const expandIndex = widgetMenuConfig.findIndex((menuItem) => menuItem.type === MenuType.EXPAND)
  if (expandIndex >= 0) {
    updatedWidgetMenuConfig[expandIndex].onClick = handleWidgetDetailsModal
  }

  // edit query
  const editQueryIndex = widgetMenuConfig.findIndex(
    (menuItem) => menuItem.type === MenuType.EDIT_QUERY
  )
  if (editQueryIndex >= 0) {
    updatedWidgetMenuConfig[editQueryIndex].onClick = () => setIsEditQueryOpen(true)
  }
  const editQueryProps = updatedWidgetMenuConfig[editQueryIndex]?.contextualComponentProps

  const notActiveWidgetMenuConfig = updatedWidgetMenuConfig.filter(
    (menuItem) =>
      ![MenuType.EDIT_QUERY, MenuType.EXPAND, MenuType.RESIZE, MenuType.REFRESH].includes(
        menuItem.type
      )
  )

  const additionalStyles = getCardAdditionalStyles(widgetSize, widget.isFilteredOut, widgetStyles)

  const isNotActive =
    widget.lifecycle.state === WidgetLifecycleState.RETIRED ||
    widget.lifecycle.state === WidgetLifecycleState.DEPRECATED

  return (
    <AccessTokenContext.Provider value={accessToken}>
      <Card
        className={classnames(styles.widget, additionalStyles)}
        data-testid={`widget-card-${widget.name}`}
        id={id}
        aria-hidden={widget.isFilteredOut}
      >
        <WidgetHeader
          widget={widget}
          menuConfig={isNotActive ? notActiveWidgetMenuConfig : updatedWidgetMenuConfig}
          createSnack={createSnack}
        />
        <WidgetContent
          widget={widget}
          chartOptions={chartOptions}
          widgetSize={widgetSize}
          widgetData={widgetData}
          isFetching={isFetching}
          errorMessage={errorMessage}
          getRefreshedWidgetData={getRefreshedWidgetData}
          isResponsive
        />
        {widget.customWidgetId && (
          <Tags>
            <Tag size='small' color='yellow'>
              customized
            </Tag>
          </Tags>
        )}
      </Card>

      {isWidgetDetailsOpen &&
        createPortal(
          <WidgetModal
            widget={widget}
            chartOptions={chartOptions}
            widgetData={widgetData}
            isFetching={isFetching}
            errorMessage={errorMessage}
            getRefreshedWidgetData={getRefreshedWidgetData}
            isShareable={isShareable}
            closeModal={handleWidgetDetailsModal}
            createSnack={createSnack}
          />,
          document.getElementById('portal') || document.body
        )}

      {hasSaveWidgetHandler(editQueryProps) &&
        isEditQueryOpen &&
        createPortal(
          <WidgetSidePanel
            closeModal={() => setIsEditQueryOpen(false)}
            widget={widget}
            widgetData={widgetData}
            createSnack={createSnack}
            accessToken={accessToken}
            chartOptions={chartOptions}
            editQueryProps={editQueryProps}
          />,
          document.getElementById('portal') || document.body
        )}
    </AccessTokenContext.Provider>
  )
}
