import { ColorPalette, WidgetSize } from '../../types-generated'
import { WidgetMessage } from '../WidgetMessage/WidgetMessage'
import { AxesChart } from './AxesChart/AxesChart'
import { BigNumberChart } from './BigNumberChart/BigNumberChart'
import { CalendarChart } from './CalendarChart/CalendarChart'
import { FormChart } from './Form/FormChart'
import { ListChart } from './ListChart/ListChart'
import { PieChart } from './PieChart/PieChart'
import { RadarChart } from './RadarChart/RadarChart'
import { ScatterChart } from './ScatterChart/ScatterChart'
import { TableChart } from './TableChart/TableChart'
import { CHART_TYPES } from './consts'
import type { ChartOptions, ChartType, ChartSize, TableColumn } from './types'
import { removeNaNValuesFromArray, is2DArray } from './utils'
import { LineChart } from 'recharts'

export type ChartProps = (typeof LineChart)['defaultProps']

export interface ChartResolverProps extends ChartProps {
  aspect?: number
  type: ChartType
  columns?: TableColumn[]
  options?: ChartOptions
  responsiveHeight?: number | string
  additionalClassName?: string
  size?: ChartSize // helper to handle widgets view in different contexts
  isExpanded?: boolean
  isResponsive?: boolean // helper to handle specific styles for widget card charts - for now used only in BigNumber
  colorPalette: ColorPalette
  widgetSize?: WidgetSize
  dataSourceURL: string
  getRefreshedWidgetData?: () => Promise<void>
}

export const ChartResolver = ({
  isExpanded = false,
  isResponsive = false,
  getRefreshedWidgetData,
  ...props
}: ChartResolverProps): JSX.Element | null => {
  if (!props.data || Object.keys(props.data).length === 0) {
    return null
  }

  let data = props.data

  switch (props.type) {
    case CHART_TYPES.AreaChart:
    case CHART_TYPES.BarChart:
    case CHART_TYPES.LineChart:
    case CHART_TYPES.StackedAreaChart:
    case CHART_TYPES.StackedBarChart:
      data = removeNaNValuesFromArray(props.data, ['drillDownUrl'])
      if (is2DArray(data)) {
        return (
          <WidgetMessage text='The format of the data cannot be represented by this type of chart.' />
        )
      }
      return <AxesChart {...props} data={data} isExpanded={isExpanded} />
    case CHART_TYPES.ScatterChart:
      data = removeNaNValuesFromArray(props.data, ['drillDownUrl'])
      return <ScatterChart {...props} data={data} isExpanded={isExpanded} />
    case CHART_TYPES.PieChart:
      data = removeNaNValuesFromArray(props.data, ['drillDownUrl'])
      return <PieChart {...props} data={data} isExpanded={isExpanded} />
    case CHART_TYPES.RadarChart:
      data = removeNaNValuesFromArray(props.data, ['drillDownUrl'])
      return <RadarChart {...props} data={data} isExpanded={isExpanded} />
    case CHART_TYPES.BigNumberChart:
      return <BigNumberChart data={data} isResponsive={isResponsive} />
    case CHART_TYPES.ListChart:
      return <ListChart data={data} size={props.size} />
    case CHART_TYPES.Table:
      return (
        <TableChart
          data={data}
          columns={props.columns}
          isExpanded={isExpanded}
          widgetSize={props.widgetSize}
        />
      )
    case CHART_TYPES.Form:
      return (
        <FormChart
          getRefreshedWidgetData={getRefreshedWidgetData}
          data={data}
          dataSourceURL={props.dataSourceURL}
        />
      )
    case CHART_TYPES.Calendar:
      return <CalendarChart data={data} isExpanded={isExpanded} />
    default:
      return null
  }
}
