import { FormChartSecret } from '../../types'
import styles from '../formChart.module.styl'
import { WidgetFormData } from '../types'
import { TextField, Text, Tooltip, IconButton, Link } from '@nike/eds'
import classNames from 'classnames'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

interface SecretProps {
  item: FormChartSecret
  control: Control<WidgetFormData>
}

export const Secret = ({ item, control }: SecretProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(!item?.defaultValue || false)

  return (
    <Controller
      name={item.name}
      control={control}
      rules={{ required: 'This field is required.' }}
      defaultValue={item?.defaultValue || ''}
      render={({ field, formState }) => (
        <>
          <div className={styles.inputWrapper}>
            <TextField
              id={item.name}
              label={item.label}
              hasErrors={!!formState.errors?.[field.name]?.message}
              errorMessage={formState.errors?.[field.name]?.message}
              className={classNames(styles.passwordField, {
                [styles.disabled]: !isPasswordVisible,
              })}
              {...field}
            />
            {item.defaultValue && (
              <div className={styles.iconWrapper}>
                {isPasswordVisible ? (
                  <Tooltip bodySlot='Hide data' placement='top' isDark>
                    <IconButton
                      label='Hide'
                      size='medium'
                      variant='secondary'
                      onClick={() => setIsPasswordVisible(false)}
                      icon='Hide'
                    />
                  </Tooltip>
                ) : (
                  <Tooltip bodySlot='Show data' placement='top' isDark>
                    <IconButton
                      label='Show'
                      size='medium'
                      variant='secondary'
                      onClick={() => setIsPasswordVisible(true)}
                      icon='Show'
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {item.drillDownUrl && item.message ? (
            <Link href={item.drillDownUrl} target='_blank'>
              {item.message}
            </Link>
          ) : (
            <Text font='body-3' color='secondary'>
              {item.message}
            </Text>
          )}
        </>
      )}
    />
  )
}
