import { MetricWidget } from '../types-generated'
import styles from '../widget.module.styl'
import { Tags } from './Tags'
import { Text, Link, Popover, IconButton } from '@nike/eds'
import moment from 'moment'
import { useState } from 'react'

interface AboutProps {
  widget: MetricWidget
}

const renderEmail = (maintainerEmail: string) => (maintainerEmail.includes('@') ? '' : '@nike.com')

export const About = ({ widget }: AboutProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const {
    description,
    contacts: { maintainerEmail, maintainerSlack, moreInfoURL },
    scope,
    tags,
    updatedBy,
    updatedOn,
  } = widget

  return (
    <Popover
      isOpen={isVisible}
      onClickOutside={() => setIsVisible(false)}
      isDark
      bodySlot={
        <div className={styles.aboutContent}>
          <Text font='title-6'>Description</Text>
          <Text font='body-2'>{description}</Text>
          {moreInfoURL && (
            <Link font='body-2' href={moreInfoURL}>
              More info
            </Link>
          )}
          <div>
            <Text<'span'> as='span' font='subtitle-1'>
              Scope:{' '}
            </Text>
            <Text<'span'> as='span' font='body-2'>
              {scope}
            </Text>
          </div>
          {updatedBy && updatedOn && (
            <div className={styles.detailsItem}>
              <Text<'span'> as='span' font='subtitle-1'>
                Last update:{' '}
              </Text>
              <Text<'span'> as='span' font='body-2'>
                {moment(updatedOn).fromNow()} by {updatedBy}
              </Text>
            </div>
          )}
          <Tags tags={tags} />
          <Text font='title-6' className='eds-spacing--mt-12'>
            Contact
          </Text>
          <Text font='body-2'>
            E-mail:{' '}
            <Link href={`mailto:${maintainerEmail}${renderEmail(maintainerEmail)}`}>
              {maintainerEmail}
              {renderEmail(maintainerEmail)}
            </Link>
          </Text>
          <Link font='body-2' href={maintainerSlack}>
            Slack
          </Link>
        </div>
      }
    >
      <IconButton
        icon='InfoCircle'
        onClick={() => setIsVisible(true)}
        aria-label='About widget'
        label='About widget'
        variant='ghost'
        size='small'
        data-testid='about-widget-btn'
      />
    </Popover>
  )
}
