import { SnackOptions } from '../../types'
import { SupportedParam, UserMetricWidget } from '../../types-generated'
import { parseQueryParam } from '../../utils/query-param-parser'
import { CustomFields, EditQueryProps, QueryParamsRow } from './types'

export enum CUSTOM_FIELDS {
  name = 'name',
  queryParams = 'queryParams',
  supportedRequiredParams = 'supportedRequiredParams',
}

export const hasSaveWidgetHandler = (props?: Record<string, any>): props is EditQueryProps =>
  !!props?.handleSaveWidget

export const createSourceURLWithParams = (queryParams: QueryParamsRow[], url?: string) => {
  const urlParams = new URL(url || '')
  queryParams.forEach((queryParam) => {
    urlParams.searchParams.append(queryParam.parameter, parseQueryParam(queryParam.value))
  })

  return urlParams.toString()
}

export const copyURL = async (url: string, createSnack?: (options: SnackOptions) => void) => {
  await navigator.clipboard.writeText(url)
  createSnack?.({
    id: 'widget-data-url-copied',
    status: 'success',
    message: 'Data Source URL copied to clipboard',
  })
}

export const generateQueryParamsOptions = (params: SupportedParam[]) =>
  [...new Set(params.map((item: SupportedParam) => item.parameter))].map((param) => ({
    label: param || '',
    value: param || '',
  }))

export const mapInboundDataToFormValues = (widget: UserMetricWidget): CustomFields => {
  const {
    name,
    queryParams,
    dataSource: { supportedParams },
  } = widget

  const filteredQueryParams = queryParams?.filter((queryParam) =>
    supportedParams
      ?.filter(({ isRequired }) => !!isRequired)
      .every(({ parameter }) => parameter !== queryParam.parameter)
  )

  const supportedRequiredParams = (supportedParams || [])
    .filter(({ isRequired }) => !!isRequired)
    .map(({ parameter, defaultValue }) => ({
      parameter: parameter || '',
      value:
        queryParams?.find((duplicate) => parameter === duplicate.parameter)?.value ||
        defaultValue ||
        '',
    }))

  return {
    name,
    queryParams: filteredQueryParams || [],
    supportedRequiredParams,
  }
}
